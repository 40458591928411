import moment from 'moment';
import * as yup from 'yup';

export interface IRevenueModel {
    customerId: string;
    length: number;
    type: string;
    term: number;
    date: string;
    amount: number;
    lineItem: string;
    planId?: string;
    status: {
        code: 'error' | 'success' | 'pending';
        message?: string;
    };
    planType: 'online' | 'local';
}

export interface IPlan {
    id: string;
    name: string;
}

export const REVENUE_TYPES = {
    ad: { value: 'ad', label: 'Ad' },
    commission: { value: 'commission', label: 'Commission' },
    local: { value: 'local', label: 'Local' },
};

export const REVENUE_TERMS = {
    net30: { value: 30, label: 'Net 30' },
    net45: { value: 45, label: 'Net 45' },
    net60: { value: 60, label: 'Net 60' },
    net90: { value: 90, label: 'Net 90' },
};

export const REVENUE_LENGHTS = [1, 2, 3, 4, 5, 6];

class Model {
    public initialValues: IRevenueModel = {
        customerId: '',
        length: 1,
        type: REVENUE_TYPES.ad.value,
        term: REVENUE_TERMS.net30.value,
        date: moment()
            .startOf('month')
            .format('MM/DD/YYYY'),
        amount: 0,
        lineItem: '',
        planId: '',
        status: { code: 'pending' },
        planType: 'online',
    };
    public validationSchema = yup.object().shape({
        customerId: yup.string().required(),
        type: yup.string().required(),
        term: yup.number().required(),
        date: yup.string().required(),
        amount: yup
            .number()
            .moreThan(0.009)
            .required(),
        lineItem: yup.string().required(),
        planId: yup.string().when('type', {
            is: (value) => value === 'local',
            then: yup.string().required(),
        }),
    });
}

const RevenueModel = new Model();

export default RevenueModel;
