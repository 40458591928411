import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme): Record<string, any> =>
    createStyles({
        light: {
            opacity: 0.5,
        },
        root: {
            maxWidth: 500,
        },
        title: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    });

export default styles;
