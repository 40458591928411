import {
    Card,
    CardContent,
    Grid, TextField,
    Typography,
    withStyles,
    IconButton,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    CircularProgress,
} from '@material-ui/core';
import React from 'react';
import styles from './styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as Icons from '@material-ui/icons';
import { Submit } from '../../components';
import moment from 'moment';

interface IProps {
    classes: any;
    actions: any;
}

interface IState {
    loading: boolean;
    id: string;
    title: string;
    body: string;
    navigationPath: string;
    sendDate: string;
    platform: string;
    response?: any;
    error?: any;
    messages: any[];
    file?: any;
    tableLoading: boolean;
    notificationTitle: string;
    notificationPriority: string;
    notificationEmoji: string;
}

class AppInbox extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        const now = moment().format("YYYY-MM-DDTHH:mm");
        this.getGlobalMessages();
        this.state = {
            loading: false,
            id: '',
            title: '',
            body: '',
            navigationPath: '',
            platform: '',
            sendDate: now,
            response: null,
            error: null,
            messages: [],
            file: null,
            tableLoading: false,
            notificationTitle: '',
            notificationPriority: '',
            notificationEmoji: '',
        };
    }

    public render() {
        const { classes } = this.props;
        const { loading, title, body, navigationPath, sendDate, response, error, id, tableLoading, notificationTitle, notificationPriority, notificationEmoji} = this.state;

        return (
            <Card square={true} className={classes.rootCard}>
                <CardContent>
                    <Typography variant="h6" gutterBottom={true}>
                        App Inbox
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom={true}>
                        Create a new message that will show up for users in their app inbox (global or user specific)
                    </Typography>

                    <Grid container={true} direction="column">
                        <Grid container={true} item={true} xs={12} sm={6} direction="column">

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="ID"
                                placeholder="Leave blank for adding new messages"
                                margin="normal"
                                onChange={(event) => this.setState({ id: event.target.value })}
                                value={id}
                            />

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Title"
                                margin="normal"
                                onChange={(event) => this.setState({ title: event.target.value })}
                                value={title}
                            />

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Body"
                                margin="normal"
                                onChange={(event) => this.setState({ body: event.target.value })}
                                value={body}
                            />

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Navigation Path (optional)"
                                placeholder="pocketpoints://gifts/1830"
                                margin="normal"
                                onChange={(event) => this.setState({ navigationPath: event.target.value })}
                                value={navigationPath}
                            />

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Notification Title"
                                margin="normal"
                                onChange={(event) => this.setState({ notificationTitle: event.target.value })}
                                value={notificationTitle}
                            />

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Notification Emoji"
                                margin="normal"
                                onChange={(event) => this.setState({ notificationEmoji: event.target.value })}
                                value={notificationEmoji}
                            />

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Notification Priority"
                                margin="normal"
                                onChange={(event) => this.setState({ notificationPriority: event.target.value })}
                                value={notificationPriority}
                            />

                            <Grid item={true} style={{ marginTop: 20 }}>
                                <TextField
                                    label="Schedule"
                                    type="datetime-local"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => this.setState({ sendDate: event.target.value })}
                                    value={sendDate}
                                    required={true}
                                />
                            </Grid>

                            <Grid item={true} style={{ marginTop: 20 }}>
                                <div>User CSV</div>
                                <input type="file" name="file" onChange={this.fileUploadHandler}/>
                            </Grid>

                            <Grid item={true} style={{ marginTop: 20 }}>
                                <Submit
                                    loading={loading}
                                    onSubmit={this.submitForm}
                                    ButtonProps={{
                                        variant: 'contained',
                                        color: 'primary',
                                        type: 'button',
                                    }}
                                >
                                    {id !== '' && "Update" }
                                    {id === '' && "Create" }
                                </Submit>
                            </Grid>

                            <Grid item={true} style={{ marginTop: 20 }}>
                                {response && this.renderLog()}
                                {error && this.renderError()}
                            </Grid>

                        </Grid>

                        {tableLoading && this.renderTableLoading()}
                        {!tableLoading && this.renderTable()}

                    </Grid>
                </CardContent>
            </Card>
        );
    }

    private renderTableLoading() {
        return (
            <Grid container={true} item={true} xs={12} style={{ marginTop: 20 }}>
                <Card raised={false} square={true} style={{width: '100%'}}>
                    <CardContent>
                        <CircularProgress />
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    private renderTable() {
        const { messages } = this.state;
        return (
            <Grid container={true} item={true} xs={12} style={{ marginTop: 20 }}>
                <Card raised={false} square={true} style={{width: '100%'}}>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Scheduled For</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Body</TableCell>
                                    <TableCell>Navigation Path</TableCell>
                                    <TableCell>Notification Title</TableCell>
                                    <TableCell>Notification Emoji</TableCell>
                                    <TableCell>Notification Priority</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {messages.map(message => (
                                    <TableRow key={message.id}>
                                        <TableCell>{moment(message.createdAt * 1000).format("MM-DD-YYYY hh:mm a")}</TableCell>
                                        <TableCell>{moment(message.sendDate * 1000).format("MM-DD-YYYY hh:mm a")}</TableCell>
                                        <TableCell>{message.title}</TableCell>
                                        <TableCell>{message.body}</TableCell>
                                        <TableCell>{message.navigationPath !== null && message.navigationPath !== "" ? message.navigationPath : "None"}</TableCell>
                                        <TableCell>{message.notification && message.notification.title && message.notification.title !== "" ? message.notification.title : "None"}</TableCell>
                                        <TableCell>{message.notification && message.notification.emoji && message.notification.emoji !== "" ? message.notification.emoji : "None"}</TableCell>
                                        <TableCell>{message.notification && message.notification.priority && message.notification.priority !== "" ? message.notification.priority : "None"}</TableCell>
                                        {this.renderSegment(message)}
                                        <TableCell>
                                            <Grid container={true} wrap='nowrap'>
                                                <Grid item={true}>
                                                    <IconButton onClick={() => this.onEditMessage(message.id)}>
                                                        <Icons.Edit/>
                                                    </IconButton>
                                                </Grid>
                                                <Grid item={true}>
                                                    <IconButton onClick={() => this.onRemoveMessage(message.id, message.platform)}>
                                                        <Icons.Delete/>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    private renderSegment(message: any) {
        if(message.type === 'csv') {
            return (
                <TableCell>
                    <a target='_blank' href={message.segment} rel="noopener noreferrer">CSV</a>
                </TableCell>
            )
        }

        return <TableCell>{message.segment}</TableCell>
    }

    private fileUploadHandler = (event: any) => {
        this.setState({
            file: event.target.files[0],
        });
    };

    private onEditMessage(id: string) {
        const { messages } = this.state;
        const message = messages.find((data) => {
            return data.id === id
        });

        this.setState({
            id,
            title: message.title,
            body: message.body,
            navigationPath: message.navigationPath !== null ? message.navigationPath : '',
            sendDate: moment(message.sendDate * 1000).format("YYYY-MM-DDTHH:mm"),
            platform: message.platform,
            notificationTitle: message.notification && message.notification.title ? message.notification.title : '',
            notificationEmoji: message.notification && message.notification.emoji ? message.notification.emoji : '',
            notificationPriority: message.notification && message.notification.priority ? message.notification.priority : '',
        })
    }

    private onRemoveMessage(id: string, platform: string) {
        const { actions } = this.props;
        this.setState({
            tableLoading: true
        });
        actions.deleteMessage(id, platform).then(() => {
            this.getGlobalMessages();
        })
    }

    private renderError() {
        const { error } = this.state;

        return <div style={{ color: 'red' }}>{error}</div>;
    }

    private renderLog() {
        const { response } = this.state;

        return (
            <Grid container={true} direction="column">
                {response.map((log: any, index: any) => {
                    return (
                        <Grid item={true} key={index}>
                            {log}
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    private submitForm = () => {
        const { actions } = this.props;
        const { title, body, navigationPath, sendDate, id, file, notificationTitle, notificationEmoji, notificationPriority} = this.state;

        const timeStamp = moment(sendDate, "YYYY-MM-DDTHH:mm").unix();

        this.setState(
            {
                loading: true,
                response: null,
                error: null,
            },
            () => {
                actions
                    .createInbox({id, title, body, navigationPath, timeStamp, file, notificationTitle, notificationEmoji, notificationPriority})
                    .then((data: any) => {
                        this.getGlobalMessages();
                        if (data.data.success === true) {
                            this.setState({
                                loading: false,
                                response: data.data.message,
                            });
                        } else {
                            this.setState({
                                loading: false,
                                error: data.data.message,
                            });
                        }
                    })
                    .catch((error: any) => {
                        this.setState({
                            loading: false,
                            error: error.toString(),
                        });
                    });
            }
        );
    };

    private getGlobalMessages = () => {
        const { actions } = this.props;
        actions.getInboxMessages().then((messages: any) => {
            this.setState({
                messages,
                tableLoading: false
            })
        })
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.messages.actions,
    };
};

export default compose(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(AppInbox);
