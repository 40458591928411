import { Dialog, DialogContent, DialogTitle, FormHelperText, TextField, Typography, withStyles } from '@material-ui/core';
import { Formik, FormikActions, FormikValues, Form } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Submit } from '../components';
import LoginModel from '../models/Login';
import styles from './Login.styles';

interface IProps {
    actions: any;
    classes?: any;
}

class Login extends React.Component<IProps> {
    public render() {
        const { classes } = this.props;

        return (
            <Formik initialValues={LoginModel.initialValues} onSubmit={this.onSubmit} validationSchema={LoginModel.validationSchema}>
                {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, status }) => (
                    <Dialog
                        open={true}
                        fullScreen={false}
                        onClose={() => null}
                        hideBackdrop={true}
                        PaperProps={{ elevation: 2, className: classes.root }}
                    >
                        <DialogTitle>
                            <div className={classes.title}>
                                <Typography variant="h5" color="primary">
                                    Passport
                                </Typography>
                                <Typography variant="subtitle1" className={classes.light}>
                                    Pocket Points Admin Tool
                                </Typography>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <Form noValidate={true}>
                                <TextField
                                    label="Email"
                                    required={true}
                                    placeholder="Enter email"
                                    id="email"
                                    autoFocus={true}
                                    value={values.email}
                                    onChange={handleChange}
                                    error={!!errors.email && !!touched.email}
                                    fullWidth={true}
                                    margin="normal"
                                />
                                <TextField
                                    label="Password"
                                    placeholder="Enter password"
                                    id="password"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    required={true}
                                    fullWidth={true}
                                    error={!!errors.password && !!touched.password}
                                    margin="normal"
                                />
                                <FormHelperText error={true}>{errors.form ? errors.form : ''}</FormHelperText>
                            </Form>
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <Submit
                                    loading={isSubmitting}
                                    onSubmit={handleSubmit}
                                    ButtonProps={{
                                        fullWidth: true,
                                        variant: 'contained',
                                        size: 'large',
                                    }}
                                >
                                    Login
                                </Submit>
                            </div>
                        </DialogContent>
                    </Dialog>
                )}
            </Formik>
        );
    }

    private onSubmit = (values: FormikValues, { setSubmitting, setFieldError }: FormikActions<any>) => {
        const { actions } = this.props;

        actions
            .login(values)
            .catch((error: any) => {
                const message = error.code === 'checkAccess' ? error.message : 'Invalid username or password';

                setFieldError('form', message);
            })
            .then(() => {
                setSubmitting(false);
            });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.user.actions,
    };
};

export default compose(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(Login);
