import axios from 'axios';
import * as firebase from 'firebase';
import 'firebase/functions';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import logger from 'redux-logger';
import Chargebee from './Chargebee';
import Classrooms from './Classrooms';
import User from './User';
import SchoolTransfer from './SchoolTransfer';
import UserRequests from './UserRequests';
import Events from './Events';
import Messages from './Messages';
import Students from './Students';

export default class Reactions {
    private reducers: any;
    private store: Store;
    private user: any;
    private chargebee: any;
    private classrooms: any;
    private schoolTransfer: any;
    private userRequests: any;
    private events: any;
    private messages: any;
    private students: any;

    constructor() {
        const firebaseApp = firebase.initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
            projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        });
        const axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API ? process.env.REACT_APP_API : '/api',
        });

        this.chargebee = new Chargebee(firebaseApp, axiosInstance);
        this.user = new User(firebaseApp);
        this.classrooms = new Classrooms(firebaseApp);
        this.schoolTransfer = new SchoolTransfer(firebaseApp, axiosInstance);
        this.userRequests = new UserRequests(firebaseApp);
        this.events = new Events(firebaseApp);
        this.messages = new Messages(firebaseApp);
        this.students = new Students(firebaseApp);

        this.reducers = combineReducers({
            chargebee: this.chargebee.reducer,
            user: this.user.reducer,
            classrooms: this.classrooms.reducer,
            schoolTransfer: this.schoolTransfer.reducer,
            userRequests: this.userRequests.reducer,
            events: this.events.reducer,
            messages: this.messages.reducer,
            students: this.students.reducer,
        });

        this.store =
            process.env.NODE_ENV === 'production' ? createStore(this.reducers) : createStore(this.reducers, applyMiddleware(logger));

        this.setStores(this.store);
    }

    public getStore = () => this.store;

    public init = () => {
        return Promise.all([this.user.init()]);
    };

    private setStores = (store: Store) => {
        this.user.setStore(store);
        this.classrooms.setStore(store);
        this.userRequests.setStore(store);
    };
}
