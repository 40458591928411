import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme): Record<string, any> =>
    createStyles({
        root: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            minHeight: '100vh',
        },
        rootCard: {
            marginBottom: theme.spacing(2),
            position: 'relative',
        },
        rootCardOverlay: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
        },
        rootExpansionPannelTable: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        rootTextLabel: {
            fontWeight: 400,
            fontSize: 12,
            opacity: 0.54,
            lineHeight: '16px',
            marginBottom: theme.spacing(1),
        },
        rootText: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '19px',
            marginBottom: theme.spacing(1),
        },
    });

export default styles;
