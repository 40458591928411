import { handleActions } from 'redux-actions';
// import { Store } from 'redux';

export default class Students {
    public actions = {
        getAppUserDetail: (studentId: number) => {
            const getAppUserDetail = this.firebase.functions().httpsCallable('getAppUserDetail');

            return getAppUserDetail({ studentId })
                .then((results: any) => {
                    if (results.data && results.data.error) {
                        return { classrooms: {} };
                    }
                    return results.data;
                })
                .catch((error: Error) => {
                    console.error(error);
                    console.info(`reactions:students:getAppUserDetail:ERROR`);
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>({}, this.initialState);

    // private store: Store;

    constructor(private firebase: any) {}

    // public setStore = (store: Store) => {
    //     this.store = store;
    // };
}
