import { Button, CircularProgress } from '@material-ui/core';
import { ButtonProps as IButtonProps } from '@material-ui/core/Button';
import React from 'react';

interface IProps {
    ButtonProps?: IButtonProps;
    classes: any;
    loading: boolean;
    onSubmit?: any;
}

export default class Submit extends React.PureComponent<IProps> {
    public render() {
        const { ButtonProps, classes, children, loading, onSubmit } = this.props;
        const props: IButtonProps = {
            size: 'medium',
            variant: 'contained',
            ...ButtonProps,
        };

        return (
            <Button type="submit" color="primary" disabled={loading} onClick={onSubmit} {...props}>
                {children}
                {loading && <CircularProgress size={24} className={classes.submitProgress} />}
            </Button>
        );
    }
}
