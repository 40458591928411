import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    overrides: {
        MuiSnackbar: {
            root: {
                borderRadius: 0,
            },
        },
    },
    palette: {
        primary: {
            main: '#00D793',
        },
        secondary: {
            main: '#000000',
        },
    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
});

export default theme;
