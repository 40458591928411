import {
    Checkbox,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Typography,
    withStyles,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    FormControlLabel,
    FormGroup,
    FormControl,
    TablePagination,
    TableFooter,
} from '@material-ui/core';
import React from 'react';
import styles from './styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as Icons from '@material-ui/icons';
import { NEW_SCHOOL_STATE } from '../../constants';

interface IProps {
    classes: any;
    actions: any;
    newSchools: any;
}

interface IState {
    loading: boolean;
    showCompleted: boolean;
    expandedPanel: string | null;
    rowsPerPage: number;
    page: number;
}

class NewSchools extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: true,
            showCompleted: false,
            expandedPanel: null,
            rowsPerPage: 25,
            page: 0,
        };
    }

    public componentDidMount() {
        const { actions } = this.props;
        const { showCompleted } = this.state;

        actions
            .getNewSchools(showCompleted)
            .then(() => {
                this.setState({ loading: false });
            })
            .catch(() => this.setState({ loading: false }));
    }

    public render() {
        const { classes, newSchools } = this.props;
        const { loading, showCompleted, rowsPerPage, page } = this.state;
        const count = newSchools ? Object.keys(newSchools).length : 0;

        return (
            <Card square={true} className={classes.rootCard}>
                {loading && (
                    <div className={classes.rootCardOverlay}>
                        <CircularProgress />
                    </div>
                )}
                <CardContent>
                    <Typography variant="h6" gutterBottom={true}>
                        New School Requests
                    </Typography>
                    <Grid container={true} justify="flex-end">
                        <FormControlLabel
                            control={<Checkbox checked={showCompleted} onChange={this.toggleShowCompleted} />}
                            label="Show Completed"
                            labelPlacement="start"
                            style={{ marginRight: 22 }}
                        />
                    </Grid>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell scope="row" className={classes.tableCell}>
                                    From
                                </TableCell>
                                <TableCell align="right">Completed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{this.renderRequests()}</TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={2}
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={(_, newPage) => this.onChangePage(newPage)}
                                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </CardContent>
            </Card>
        );
    }

    private renderRequests = () => {
        const { newSchools } = this.props;
        const { expandedPanel, rowsPerPage, page } = this.state;
        const start = rowsPerPage * page;
        const end = start + rowsPerPage;

        if (!newSchools) {
            return null;
        }

        return Object.keys(newSchools)
            .sort()
            .slice(start, end)
            .map((key, index) => {
                const { classes } = this.props;
                const schoolRequest = {
                    ...newSchools[key],
                    portalState: newSchools[key].portalState ? newSchools[key].portalState : NEW_SCHOOL_STATE.NOT_SAVED,
                };

                return (
                    <TableRow key={index}>
                        <TableCell scope="row" className={classes.tableCell}>
                            <ExpansionPanel expanded={expandedPanel === key} onChange={() => this.expandPanel(key)} elevation={0}>
                                <ExpansionPanelSummary expandIcon={<Icons.ExpandMore />} className={classes.rootExpansionPannelTable}>
                                    <Typography className={classes.email}>{schoolRequest.email}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.rootExpansionPannelTable}>
                                    {expandedPanel === key && (
                                        <Grid container={true} direction="column">
                                            <Typography className={classes.rootTextLabel}>School Name:</Typography>
                                            <Typography className={classes.rootText} gutterBottom={true}>
                                                {schoolRequest.name}
                                            </Typography>
                                            <Typography className={classes.rootTextLabel}>Address:</Typography>
                                            <Typography className={classes.rootText} gutterBottom={true}>
                                                {schoolRequest.address}
                                            </Typography>
                                            <Typography className={classes.rootTextLabel}>Request State:</Typography>
                                            <FormControl className={classes.formControl}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={schoolRequest.portalState === NEW_SCHOOL_STATE.NOT_SAVED}
                                                                onChange={(_, checked) =>
                                                                    this.updatePortalState({
                                                                        key,
                                                                        value: NEW_SCHOOL_STATE.NOT_SAVED,
                                                                        checked,
                                                                    })
                                                                }
                                                            />
                                                        }
                                                        label="Not Saved"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={schoolRequest.portalState === NEW_SCHOOL_STATE.ADDED}
                                                                onChange={(_, checked) =>
                                                                    this.updatePortalState({
                                                                        key,
                                                                        value: NEW_SCHOOL_STATE.ADDED,
                                                                        checked,
                                                                    })
                                                                }
                                                            />
                                                        }
                                                        label="Added in Portal"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={schoolRequest.portalState === NEW_SCHOOL_STATE.EXISTED}
                                                                onChange={(_, checked) =>
                                                                    this.updatePortalState({
                                                                        key,
                                                                        value: NEW_SCHOOL_STATE.EXISTED,
                                                                        checked,
                                                                    })
                                                                }
                                                            />
                                                        }
                                                        label="Existed in Portal"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={schoolRequest.portalState === NEW_SCHOOL_STATE.NOT_APPLICABLE}
                                                                onChange={(_, checked) =>
                                                                    this.updatePortalState({
                                                                        key,
                                                                        value: NEW_SCHOOL_STATE.NOT_APPLICABLE,
                                                                        checked,
                                                                    })
                                                                }
                                                            />
                                                        }
                                                        label="N/A"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!schoolRequest.emailedUser}
                                                        onChange={(_, checked) => this.updateEmailedUser(key, checked)}
                                                    />
                                                }
                                                label="Emailed User"
                                                labelPlacement="end"
                                                style={{ marginRight: 22 }}
                                            />
                                        </Grid>
                                    )}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </TableCell>
                        <TableCell align="right">
                            <Checkbox checked={schoolRequest.completed} disabled={true} />
                        </TableCell>
                    </TableRow>
                );
            });
    };

    private onChangePage = (page: number) => {
        this.setState({ page });
    };

    private onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const rowsPerPage = parseInt(target.value, 10);

        this.setState({ rowsPerPage });
    };

    private expandPanel = (key: string) => {
        const expandedPanel = this.state.expandedPanel === key ? null : key;

        this.setState({ expandedPanel });
    };

    private updatePortalState = (update: { key: string; value: any; checked: boolean }) => {
        const { key, value } = update;
        const { actions, newSchools } = this.props;

        actions.updateNewSchool(key, { emailedUser: newSchools[key].emailedUser, portalState: value });
    };

    private updateEmailedUser = (key: string, checked: boolean) => {
        const { actions, newSchools } = this.props;
        const portalState = newSchools[key].portalState ? newSchools[key].portalState : NEW_SCHOOL_STATE.NOT_SAVED;

        actions.updateNewSchool(key, { emailedUser: checked, portalState });
    };

    private toggleShowCompleted = () => {
        const { actions } = this.props;

        this.setState({ showCompleted: !this.state.showCompleted, loading: true, page: 0 }, () => {
            actions
                .getNewSchools(this.state.showCompleted)
                .then(() => {
                    this.setState({ loading: false });
                })
                .catch(() => this.setState({ loading: false }));
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.userRequests.actions,
        newSchools: state.userRequests.data.newSchools,
    };
};

export default compose(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(NewSchools);
