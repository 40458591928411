import MomentUtils from '@date-io/moment';
import { CircularProgress, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Reactions from '../reactions';
import theme from '../theme/theme';
import styles from './App.styles';
import Layout from './Layout';

interface IProps {
    classes: any;
}

interface IState {
    initted: boolean;
}

class App extends React.Component<IProps, IState> {
    private reactions = new Reactions();
    private store = this.reactions.getStore();

    constructor(props: IProps) {
        super(props);

        this.state = {
            initted: false,
        };
    }

    public componentDidMount() {
        this.reactions
            .init()
            .then(() => {
                this.setState({ initted: true });
            })
            .catch((error) => {
                console.error(error);

                this.setState({ initted: true });
            });
    }

    public render() {
        return (
            <Router basename="/">
                <Provider store={this.store}>
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>{this.renderLayout()}</MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </Provider>
            </Router>
        );
    }

    private renderLayout = () => {
        const { classes } = this.props;
        const { initted } = this.state;

        if (initted) {
            return <Layout />;
        }

        return (
            <div className={classes.root}>
                <CircularProgress />
            </div>
        );
    };
}

export default withStyles(styles)(App);
