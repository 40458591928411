import { createStyles, Theme } from '@material-ui/core';
import appStyles from '../App.styles';

const styles = (theme: Theme): Record<string, any> => {
    const app = appStyles(theme);
    const local = createStyles({
        flex: {
            flex: 1,
        },
        alignFab: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        customerId: {
            minWidth: 50,
        },
        marginBottom: {
            marginBottom: theme.spacing(1),
        },
        modal: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    });

    return { ...app, ...local };
};

export default styles;
