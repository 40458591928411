import {
    Card,
    CardContent,
    Grid, TextField,
    Typography,
    withStyles,
    IconButton,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    CircularProgress
} from '@material-ui/core';
import React from 'react';
import styles from './styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as Icons from '@material-ui/icons';
import { Submit } from '../../components';
import moment from 'moment';

interface IProps {
    classes: any;
    actions: any;
}

interface IState {
    loading: boolean;
    subject: string;
    body: string;
    sendDate: string;
    response?: any;
    error?: any;
    messages: any[];
    file?: any;
    companyId: string;
    deleting: boolean;
    previewEmail: string;
}

class EmailMessage extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        const now = moment().format("YYYY-MM-DDTHH:mm");
        this.getGlobalMessages();
        this.state = {
            loading: false,
            subject: '',
            body: '',
            sendDate: now,
            response: null,
            error: null,
            messages: [],
            file: null,
            companyId: '',
            deleting: false,
            previewEmail: '',
        };
    }

    public render() {
        const { classes } = this.props;
        const { loading, subject, body, sendDate, response, error, companyId, deleting, previewEmail } = this.state;

        return (
            <Card square={true} className={classes.rootCard}>
                <CardContent>
                    <Typography variant="h6" gutterBottom={true}>
                        Email
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom={true}>
                        Create a new email to be scheduled out.
                    </Typography>

                    <Grid container={true} direction="column">
                        <Grid container={true} item={true} xs={12} sm={6} direction="column">

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Subject"
                                margin="normal"
                                onChange={(event) => this.setState({ subject: event.target.value })}
                                value={subject}
                            />

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Body"
                                margin="normal"
                                onChange={(event) => this.setState({ body: event.target.value })}
                                multiline={true}
                                rows={8}
                                value={body}
                            />

                            <Grid item={true} style={{ marginTop: 20 }}>
                                <TextField
                                    label="Schedule"
                                    type="datetime-local"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(event) => this.setState({ sendDate: event.target.value })}
                                    value={sendDate}
                                    required={true}
                                />
                            </Grid>


                            <Grid item={true} style={{ marginTop: 80, fontSize: 20 }}>
                                User Segmentation
                                <hr/>
                            </Grid>

                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Company ID (optional)"
                                margin="normal"
                                onChange={(event) => this.setState({ companyId: event.target.value })}
                                value={companyId}
                            />

                            <Grid item={true} style={{ marginTop: 20 }}>
                                <div>User CSV</div>
                                <input type="file" name="file" onChange={this.fileUploadHandler}/>
                            </Grid>

                            <Grid item={true} style={{ marginTop: 60 }}>
                                <Submit
                                    loading={loading}
                                    onSubmit={this.submitForm}
                                    ButtonProps={{
                                        variant: 'contained',
                                        color: 'primary',
                                        type: 'button',
                                    }}
                                >
                                    Create
                                </Submit>
                            </Grid>

                            <Grid item={true} style={{ marginTop: 80, fontSize: 20 }}>
                                Send Preview
                                <hr/>
                            </Grid>
                            <TextField
                                fullWidth={true}
                                id="standard-textarea"
                                label="Preview Email"
                                placeholder = 'rob@pocketpoints.com'
                                margin="normal"
                                onChange={(event) => this.setState({ previewEmail: event.target.value })}
                                value={previewEmail}
                            />
                            <Grid item={true} style={{ marginTop: 60 }}>
                                <Submit
                                    loading={loading}
                                    onSubmit={this.submitPreview}
                                    ButtonProps={{
                                        variant: 'contained',
                                        color: 'primary',
                                        type: 'button',
                                    }}
                                >
                                    Send Preview
                                </Submit>
                            </Grid>

                            <Grid item={true} style={{ marginTop: 20 }}>
                                {response && this.renderLog()}
                                {error && this.renderError()}
                            </Grid>

                        </Grid>

                        {deleting && this.rednerTableLoading()}
                        {!deleting && this.renderTable()}

                    </Grid>
                </CardContent>
            </Card>
        );
    }

    private rednerTableLoading() {
        return (
            <Grid container={true} item={true} xs={12} style={{ marginTop: 20 }}>
                <Card raised={false} square={true} style={{width: '100%'}}>
                    <CardContent>
                        <CircularProgress />
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    private renderTable() {
        const { messages } = this.state;
        return (
            <Grid container={true} item={true} xs={12} style={{ marginTop: 20 }}>
                <Card raised={false} square={true} style={{width: '100%'}}>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Created At</TableCell>
                                    <TableCell>Scheduled For</TableCell>
                                    <TableCell>Subject</TableCell>
                                    <TableCell>Segment</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {messages.map(message => (
                                    <TableRow key={message.id}>
                                        <TableCell>{moment(message.createdAt * 1000).format("MM-DD-YYYY hh:mm a")}</TableCell>
                                        <TableCell>{moment(message.sendDate * 1000).format("MM-DD-YYYY hh:mm a")}</TableCell>
                                        <TableCell>{message.subject}</TableCell>
                                        {this.renderSegment(message)}
                                        <TableCell>
                                            <Grid container={true} wrap='nowrap'>
                                                <Grid item={true}>
                                                    {this.renderDeleteButton(message)}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    private renderSegment(message: any) {
        if(message.type === 'csv') {
            return (
                <TableCell>
                    <a target='_blank' href={message.segment} rel="noopener noreferrer">CSV</a>
                </TableCell>
            )
        }

        return <TableCell>{message.segment}</TableCell>
    }

    private renderDeleteButton(message: any) {
        const sentAt = moment(message.sendDate * 1000);
        const now = moment();
        if(sentAt.isBefore(now)) {
            return null;
        }

        return (
            <IconButton onClick={() => this.onRemoveMessage(message.id, message.platform)}>
                <Icons.Delete/>
            </IconButton>
        )
    }

    private fileUploadHandler = (event: any) => {
        this.setState({
            file: event.target.files[0],
        });
    };

    private onRemoveMessage(id: string, platform: string) {
        const { actions } = this.props;
        this.setState({
            deleting: true
        });
        actions.deleteMessage(id, platform).then(() => {
            this.getGlobalMessages();
        })
    }

    private renderError() {
        const { error } = this.state;

        return <div style={{ color: 'red' }}>{error}</div>;
    }

    private renderLog() {
        const { response } = this.state;

        return (
            <Grid container={true} direction="column">
                {response.map((log: any, index: any) => {
                    return (
                        <Grid item={true} key={index}>
                            {log}
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    private submitPreview = () => {
        const { actions } = this.props;
        const { subject, body, previewEmail} = this.state;

        this.setState(
            {
                loading: true,
                response: null,
                error: null,
            },
            () => {
                actions
                    .sendPreviewEmail({subject, body, previewEmail})
                    .then((data: any) => {
                        this.getGlobalMessages();
                        if (data.data.success === true) {
                            this.setState({
                                loading: false,
                                response: data.data.message,
                            });
                        } else {
                            this.setState({
                                loading: false,
                                error: data.data.message,
                            });
                        }
                    })
                    .catch((error: any) => {
                        this.setState({
                            loading: false,
                            error: error.toString(),
                        });
                    });
            }
        );
    };

    private submitForm = () => {
        const { actions } = this.props;
        const { subject, body, sendDate, companyId, file } = this.state;

        const timeStamp = moment(sendDate, "YYYY-MM-DDTHH:mm");

        this.setState(
            {
                loading: true,
                response: null,
                error: null,
            },
            () => {
                actions
                    .createEmail({subject, body, timeStamp, file, companyId })
                    .then((data: any) => {
                        this.getGlobalMessages();
                        if (data.data.success === true) {
                            this.setState({
                                loading: false,
                                response: data.data.message,
                            });
                        } else {
                            this.setState({
                                loading: false,
                                error: data.data.message,
                            });
                        }
                    })
                    .catch((error: any) => {
                        this.setState({
                            loading: false,
                            error: error.toString(),
                        });
                    });
            }
        );
    };

    private getGlobalMessages = () => {
        const { actions } = this.props;
        actions.getEmailMessages().then((messages: any) => {
            this.setState({
                messages,
                deleting: false
            })
        })
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.messages.actions,
    };
};

export default compose(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(EmailMessage);
