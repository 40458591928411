import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme): Record<string, any> =>
    createStyles({
        submit: {
            marginTop: theme.spacing(2),
        },
        submitProgress: {
            position: 'absolute',
        },
    });

export default styles;
