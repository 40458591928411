import { Card, CardContent, Grid, Link, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import MaterialTable from 'material-table';
// import moment from 'moment-timezone';
import styles from './styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Submit } from '../../components';

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
});

interface IProps {
    classes: any;
    actions: any;
    user: any;
}

interface IState {
    classrooms: any[];
    joinedClassroomIds: string[];
    earningWindowId: string;
    teacherId: string;
}

class ClassroomData extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            classrooms: [],
            joinedClassroomIds: [],
            earningWindowId: '',
            teacherId: '',
        };
    }

    public render() {
        const { classes } = this.props;

        return (
            <Card square={true} className={classes.rootCard}>
                <CardContent>
                    <Typography variant="h6" gutterBottom={true}>
                        Classroom Data
                    </Typography>
                    <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={this.handleSearch}>
                        {({ values, errors, touched, handleChange, setFieldTouched, isSubmitting }) => (
                            <Form noValidate={true}>
                                <Grid container={true} direction="column" wrap="nowrap">
                                    <Grid item={true} xs={12} sm={4}>
                                        <TextField
                                            id="email"
                                            fullWidth={true}
                                            label="Teacher Email"
                                            placeholder="enter the teacher's email"
                                            margin="normal"
                                            onChange={handleChange}
                                            value={values.email}
                                            error={!!(errors.email && touched.email)}
                                        />
                                    </Grid>
                                    <Grid item={true} xs={12} style={{ paddingTop: 16 }}>
                                        <Submit
                                            loading={isSubmitting}
                                            ButtonProps={{
                                                variant: 'contained',
                                                color: 'primary',
                                            }}
                                        >
                                            Search
                                        </Submit>
                                    </Grid>
                                    <Grid item={true} xs={12} style={{ paddingTop: 16 }}>
                                        {this.renderClassrooms()}
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        );
    }

    private renderClassrooms = () => {
        const { classrooms, joinedClassroomIds } = this.state;
        const data = Object.keys(classrooms).map((classroomId) => classrooms[classroomId]);

        return (
            <MaterialTable
                options={{
                    toolbar: false,
                    header: data.length > 0,
                    paging: false,
                    actionsColumnIndex: -1,
                }}
                columns={[
                    { title: 'Id', field: 'classroomId', cellStyle: { width: 180 }, headerStyle: { width: 180, height: 55 } },
                    { title: 'Name', field: 'attributes.name' },
                ]}
                data={data}
                components={{
                    Container: (props) => <Paper {...props} elevation={0} style={{ width: '100%' }} />,
                }}
                actions={[
                    (rowData) => {
                        const { classroomId } = rowData;

                        if (joinedClassroomIds.indexOf(classroomId) > -1) {
                            return {
                                icon: 'remove',
                                tooltip: 'Remove your support user to this classroom',
                                onClick: () => this.handleRemoveClassroom(rowData),
                            };
                        }

                        return {
                            icon: 'add',
                            tooltip: 'Add your support user to this classroom',
                            onClick: () => this.handleAddClassroom(rowData),
                        };
                    },
                ]}
                detailPanel={(rowData) => (
                    <Grid container={true} direction="column" wrap="nowrap" style={{ padding: 32 }}>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>Class Size: </span>
                                {rowData.attributes.groupSize}
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>Invite Code: </span>
                                {rowData.attributes.inviteCode}
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>School Id: </span>
                                {rowData.attributes.schoolId}
                                <Link
                                    target="_blank"
                                    href={`${process.env.REACT_APP_PORTAL_SITE}/employee/school/?filters[id]=${rowData.attributes.schoolId}`}
                                    style={{ verticalAlign: 'middle' }}
                                >
                                    <OpenInNewIcon style={{ paddingLeft: 8 }} />
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>Timezone: </span>
                                {rowData.attributes.window.timeZone}
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>Students: </span>
                            </Typography>
                            {this.renderStudents(rowData)}
                        </Grid>
                    </Grid>
                )}
            />
        );
    };

    private renderStudents = ({ attributes, students }: any) => {
        const data = Object.keys(students).map((studentId) => {
            return {
                ...students[studentId],
                studentId,
                mpUrl: `explore#list/filter_by_cohort:(behaviors:(),description:'',is_locked:!f,is_visible:!t,name:'',selector:(children:!((children:!((children:!((property:predefined_cohort,value:'$all_users'),(children:!((children:!((children:!((property:user,value:'User%20Id')),operator:string),(property:literal,value:!('${studentId}'))),operator:equals_any),(property:literal,value:!f)),operator:or)),operator:and),(property:literal,value:!f)),operator:or),(property:literal,value:!t)),operator:and)),showing_columns:!('$last_seen',OS,Version,'OS%20Version','$unsubscribed'),sort_order:descending,sort_property:'$last_seen',sort_property_type:datetime`,
            };
        });

        // console.info(data);
        // const {
        //     window: { timeZone },
        // } = attributes;

        return (
            <MaterialTable
                options={{
                    paging: false,
                    toolbar: false,
                    header: data.length > 0,
                }}
                columns={[{ title: 'Name', field: 'name' }]}
                data={data}
                components={{
                    Container: (props) => <Paper {...props} elevation={0} style={{ width: '100%' }} />,
                }}
                detailPanel={(rowData) => (
                    <Grid container={true} direction="column" wrap="nowrap" style={{ padding: 32 }}>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>App User Id: </span>
                                {rowData.studentId}
                                <Link
                                    target="_blank"
                                    href={`${process.env.REACT_APP_PORTAL_SITE}/employee/appusers?filters[id]=${rowData.studentId}`}
                                    style={{ verticalAlign: 'middle' }}
                                >
                                    <OpenInNewIcon style={{ paddingLeft: 8 }} />
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>Current School Id: </span>
                                {rowData.currentSchoolId}
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>Default School Id: </span>
                                {rowData.defaultSchoolId}
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>Total Seconds: </span>
                                {rowData.totalSecondsInWindow}
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Typography gutterBottom={true}>
                                <span style={{ fontWeight: 600 }}>Earned Events: </span>
                            </Typography>
                            {/*{this.renderEarnedEvents(rowData, timeZone)}*/}
                        </Grid>
                    </Grid>
                )}
            />
        );
    };

    // private renderEarnedEvents = ({ earned }: any, timeZone: string) => {
    //     const data = earned
    //         .map((event: any) => {
    //             return {
    //                 ...event,
    //                 start: moment.tz(event.start, timeZone).format('M/D/YY h:mm:ss A'),
    //                 end: moment.tz(event.end, timeZone).format('M/D/YY h:mm:ss A'),
    //                 sort: moment.tz(event.start, timeZone).unix(),
    //             };
    //         })
    //         .sort((eventA: any, eventB: any) => {
    //             return eventA.sort < eventB.sort ? 1 : -1;
    //         });
    //
    //     return (
    //         <MaterialTable
    //             options={{
    //                 toolbar: false,
    //                 paging: false,
    //                 header: data.length > 0,
    //             }}
    //             columns={[
    //                 { title: 'Start', field: 'start' },
    //                 { title: 'End', field: 'end' },
    //                 { title: 'Seconds', field: 'secondsInWindow' },
    //             ]}
    //             data={data}
    //             components={{
    //                 Container: (props) => <Paper {...props} elevation={0} style={{ width: '100%', paddingLeft: 32 }} />,
    //             }}
    //         />
    //     );
    // };

    private handleAddClassroom = (rowData: any) => {
        const { actions } = this.props;
        const { classroomId } = rowData;

        this.setState({ joinedClassroomIds: [...this.state.joinedClassroomIds, classroomId] }, () => {
            const { earningWindowId, teacherId, joinedClassroomIds } = this.state;

            actions.updateSupportUserClassrooms(teacherId, earningWindowId, joinedClassroomIds);
        });
    };

    private handleRemoveClassroom = (rowData: any) => {
        const { actions } = this.props;
        const { classroomId } = rowData;

        this.setState({ joinedClassroomIds: this.state.joinedClassroomIds.filter((id: string) => id !== classroomId) }, () => {
            const { earningWindowId, teacherId, joinedClassroomIds } = this.state;

            actions.updateSupportUserClassrooms(teacherId, earningWindowId, joinedClassroomIds);
        });
    };

    private handleSearch = (values: any, { setSubmitting }: any) => {
        const { actions, user } = this.props;
        const { email } = values;

        actions
            .getPortalUserDetail(email)
            .then((data: any) => {
                const { earningWindowId, teacherId } = data;
                const joinedClassroomIds: string[] = [];
                const classrooms = Object.keys(data.classrooms).map((classroomId: string) => {
                    const classroom = data.classrooms[classroomId];

                    if (classroom.portalUsers.indexOf(user.supportUser.id) > -1) {
                        joinedClassroomIds.push(classroomId);
                    }

                    return { classroomId, ...classroom };
                });

                this.setState({ classrooms, joinedClassroomIds, earningWindowId, teacherId }, () => {
                    setSubmitting(false);
                });
            })
            .catch((error: any) => {
                console.info(error);

                this.setState({ classrooms: [] }, () => {
                    setSubmitting(false);
                });
            });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.classrooms.actions,
        classrooms: state.classrooms.data,
        user: state.user.data,
    };
};

export default compose(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(ClassroomData);
