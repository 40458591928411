import { handleActions } from 'redux-actions';
import { Store } from 'redux';

export default class Classrooms {
    public actions = {
        getPortalUserDetail: (email: string) => {
            const getPortalUserDetail = this.firebase.functions().httpsCallable('getPortalUserDetail');

            return getPortalUserDetail({ email })
                .then((results: any) => {
                    if (results.data && results.data.error) {
                        return { classrooms: {} };
                    }
                    return results.data;
                })
                .catch((error: Error) => {
                    console.error(error);
                    console.info(`reactions:classrooms:getPortalUserDetail:ERROR`);
                });
        },
        updateSupportUserClassrooms: (teacherId: string, earningWindowId: string, classroomIds: string[]) => {
            const supportUserId = this.store.getState().user.data.supportUser.id;
            const updateSupportUserClassrooms = this.firebase.functions().httpsCallable('updateSupportUserClassrooms');

            return updateSupportUserClassrooms({ teacherId, earningWindowId, classroomIds, supportUserId })
                .then((results: any) => {
                    if (results.data && results.data.error) {
                        return { classrooms: {} };
                    }
                    return results.data;
                })
                .catch((error: Error) => {
                    console.error(error);
                    console.info(`reactions:classrooms:updateSupportUserClassrooms:ERROR`);
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>({}, this.initialState);

    private store: Store;

    constructor(private firebase: any) {}

    public setStore = (store: Store) => {
        this.store = store;
    };
}
