import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    withStyles,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { ChargebeeIcon } from '../components';
import { ROUTES } from '../constants';
import Chargebee from './Chargebee';
import Rewards from './Rewards';
import Dashboard from './Dashboard';
import Messages from './Messages';
import styles from './Layout.styles';
import Login from './Login';

interface IProps extends RouteComponentProps {
    actions: any;
    classes: any;
    user: any;
}

interface IState {
    drawerOpen: boolean;
}

const components = {
    dashboard: {
        Component: Dashboard,
        Icon: Icons.Dashboard,
        path: ROUTES.dashboard,
        title: 'Dashboard',
    },
    chargebee: {
        Component: Chargebee,
        Icon: ChargebeeIcon,
        path: ROUTES.chargebee,
        title: 'Chargebee Utils',
    },
    rewards: {
        Component: Rewards,
        Icon: Icons.School,
        path: ROUTES.rewards,
        title: 'Teacher Rewards',
    },
    messages: {
        Component: Messages,
        Icon: Icons.Message,
        path: ROUTES.messages,
        title: 'Messages',
    },
};

class Layout extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            drawerOpen: false,
        };
    }

    public render() {
        const { actions, classes, user } = this.props;
        const { drawerOpen } = this.state;
        const title = this.getTitle();

        document.title = `Passport - ${user ? title : 'Login'}`;

        if (!user) {
            return <Login />;
        }

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: drawerOpen,
                    })}
                >
                    <Toolbar
                        disableGutters={true}
                        className={classNames(classes.toolbarGutter, {
                            [classes.toolbarGutterOpen]: drawerOpen,
                        })}
                    >
                        <IconButton
                            onClick={this.toggleDrawer}
                            className={classNames(classes.menuButton, {
                                [classes.hide]: drawerOpen,
                            })}
                        >
                            <Icons.Menu />
                        </IconButton>
                        <Typography variant="h6" noWrap={true}>
                            {title}
                        </Typography>
                        <div className={classes.grow} />
                        <IconButton onClick={actions.logout}>
                            <Icons.AccountCircle />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <MediaQuery maxWidth={600}>
                    {(matches: boolean) => (
                        <Drawer
                            variant={matches ? undefined : 'permanent'}
                            className={classNames(classes.drawer, {
                                [classes.drawerOpen]: drawerOpen,
                                [classes.drawerClose]: !drawerOpen,
                            })}
                            classes={{
                                paper: classNames({
                                    [classes.drawerOpen]: drawerOpen,
                                    [classes.drawerClose]: !drawerOpen,
                                }),
                            }}
                            open={drawerOpen}
                        >
                            <div className={classes.toolbar}>
                                <IconButton onClick={this.toggleDrawer}>
                                    <Icons.ChevronLeft />
                                </IconButton>
                            </div>
                            <Divider />
                            <List>{this.renderMenu()}</List>
                        </Drawer>
                    )}
                </MediaQuery>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Switch>{this.renderRoutes()}</Switch>
                </main>
            </div>
        );
    }

    private renderMenu = () => {
        const {
            classes,
            history: { location },
        } = this.props;

        return Object.keys(components).map((key, index) => {
            const { Icon, path, title } = components[key];

            return (
                <Link key={index} to={path}>
                    <ListItem button={true} selected={path === location.pathname}>
                        <ListItemIcon className={classes.icon}>
                            <Icon />
                        </ListItemIcon>
                        <ListItemText primary={title} />
                    </ListItem>
                </Link>
            );
        });
    };

    private renderRoutes = () => {
        return Object.keys(components).map((key, index) => {
            const { Component, path } = components[key];

            return <Route key={index} exact={true} path={path} component={Component} />;
        });
    };

    // private renderLink = (props: LinkProps, to: string) => {
    //     return <Link to={to} {...props} />;
    // };

    private toggleDrawer = () => {
        const { drawerOpen } = this.state;

        this.setState({ drawerOpen: !drawerOpen });
    };

    private getTitle = () => {
        const {
            history: { location },
        } = this.props;
        const found = Object.keys(components).find((key) => {
            return components[key].path === location.pathname;
        });

        return found ? components[found].title : '';
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.user.actions,
        user: state.user.data,
    };
};

export default compose(
    withRouter,
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(Layout);
