export const ROUTES = {
    chargebee: '/chargebee',
    dashboard: '/',
    users: '/users',
    rewards: '/rewards',
    messages: '/messages',
};
export const NEW_SCHOOL_STATE = {
    ADDED: 'ADDED',
    EXISTED: 'EXISTED',
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    NOT_SAVED: 'NOT_SAVED',
};
export const UPDATE_SCHOOL_STATE = {
    UPDATED: 'UPDATED',
    NOT_APPLICABLE: 'NOT_APPLICABLE',
    NOT_SAVED: 'NOT_SAVED',
};
export const WEEKDAY_MASK = {
    monday: 32,
    tuesday: 16,
    wednesday: 8,
    thursday: 4,
    friday: 2,
    saturday: 1,
    sunday: 64,
};
