import { AxiosInstance } from 'axios';
// import { Store } from 'redux';
import { handleActions } from 'redux-actions';
import { IRevenueModel } from '../models/Revenue';

export default class ChargebeeReaction {
    public actions = {
        createInvoices: (invoices: IRevenueModel[]) => {
            return this.firebase
                .auth()
                .currentUser.getIdToken()
                .then((token: string) => {
                    return this.axios
                        .post(
                            '/chargebee/invoices/create',
                            { data: invoices },
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        )
                        .then((results) => results.data);
                });
        },
        getPlans: () => {
            return this.firebase
                .auth()
                .currentUser.getIdToken()
                .then((token: string) => {
                    return this.axios
                        .get('/chargebee/plans', {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        .then((results) => results.data);
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>({}, this.initialState);

    // private store: Store;

    constructor(private firebase: any, private axios: AxiosInstance) {
        // console.info('chargebee', this.firebase, this.axios, this.store);
    }

    // public setStore = (store: Store) => {
    //     this.store = store;
    // };
}
