import { createStyles, Theme } from '@material-ui/core';
import appStyles from '../App.styles';

const styles = (theme: Theme): Record<string, any> => {
    const app = appStyles(theme);
    const local = createStyles({
        tableCell: { width: '100%', paddingRight: 0 },
        formControl: { width: 'fit-content', paddingLeft: 20 },
        email: { userSelect: 'text', cursor: 'default' },
    });

    return { ...app, ...local };
};

export default styles;
