import * as yup from 'yup';

export interface ILoginModel {
    email: string;
    password: string;
}

class Model {
    public initialValues: ILoginModel = {
        email: '',
        password: '',
    };
    public validationSchema = yup.object().shape({
        email: yup
            .string()
            .email()
            .required(),
        password: yup.string().required(),
    });
}

const LoginModel = new Model();

export default LoginModel;
