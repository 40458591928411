import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { ILoginModel } from '../models/Login';

export default class UserReaction {
    public actions = {
        checkAccess: (uid: string) => {
            return this.firebase
                .database()
                .ref(`/passportUsers/${uid}`)
                .once('value')
                .then((snapshot: any) => {
                    const data = snapshot.val();

                    if (!data) {
                        throw new Error();
                    }

                    return data;
                })
                .catch(() => {
                    const error: any = new Error(`Sorry, you don't have access to Passport`);

                    error.code = 'checkAccess';

                    throw error;
                });
        },
        login: ({ email, password }: ILoginModel) => {
            return this.firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(({ user }: any) => this.actions.checkAccess(user.uid));
        },
        logout: () => {
            this.firebase.auth().signOut();
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>(
        {
            SET_USER: (state, action) => {
                return {
                    ...state,
                    data: action.payload,
                };
            },
        },
        this.initialState
    );

    private setUser = createAction('SET_USER');

    private store: Store;

    constructor(private firebase: any) {}

    public init = () => {
        return new Promise((resolve) => {
            this.firebase.auth().onAuthStateChanged((user: any) => {
                if (user && user.uid) {
                    return this.actions
                        .checkAccess(user.uid)
                        .then(({ name, role, supportUser }: any) => {
                            this.store.dispatch(
                                this.setUser({
                                    name,
                                    role,
                                    supportUser,
                                    uid: user.uid,
                                })
                            );
                        })
                        .catch(() => {
                            this.actions.logout();
                        })
                        .then(() => resolve());
                }

                this.store.dispatch(this.setUser(null));

                return resolve();
            });
        });
    };

    public setStore = (store: Store) => {
        this.store = store;
    };
}
