import { handleActions } from 'redux-actions';
import moment from 'moment';

export interface IBaseJob {
    args: {
        options: any
        params: any
        payload: string
    }
    cron: null
    due: Date
    name: string
    options: {
        output: boolean
        outputHistory: boolean
    }
    profile: {
        memory: string
    }
    retry: boolean
    state: string
    target: string
}

export default class MessagesReaction {
    public actions = {
        getPushMessages: () => {
            return this.getMessages('push');
        },
        getEmailMessages: () => {
            return this.getMessages('email');
        },
        getInboxMessages: () => {
            const ref = this.firebase.firestore().collection('messages');
            return ref.get().then((querySnapshot: any) => {
                const messages: any[] = [];
                querySnapshot.forEach((doc: any) => {
                    if (doc.data().platform === 'users' || doc.data().platform === 'app') {
                        let message = doc.data();

                        let segment = 'All';
                        if (message.type === 'csv') {
                            segment = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGEBUCKET}/o/messages%2F${message.fileName}?alt=media`;
                        }

                        message = {
                            ...message,
                            segment
                        };

                        messages.push(message);
                    }
                });
                return messages.sort((lhs: any, rhs: any) => {
                    return lhs.createdAt < rhs.createdAt ? 1 : -1
                });
            });
        },
        deleteMessage: (id: string, platform: string) => {
            if (platform === 'users') {
                return this.deleteUserInbox(id);
            }
            else if (platform === 'push' || platform === 'email') {
                return this.deleteMessageJobs(id);
            }
            return this.firebase.firestore().collection('messages').doc(id).delete()
        },
        createInbox: async (data: any) => {
            const {
                id,
                title,
                body,
                navigationPath,
                timeStamp,
                file,
                notificationTitle,
                notificationEmoji,
                notificationPriority
            } = data;

            if (title == null || title === "" || body == null || body === "") {
                return Promise.resolve({
                    data: {
                        success: false,
                        message: ["Title and body are required"]
                    }
                })
            }

            let newMessage: any = {};
            let ref;
            const now = moment().unix();
            if (id == null || id === '') {
                ref = this.firebase.firestore().collection('messages').doc();
                newMessage = {
                    ...newMessage,
                    createdAt: now
                }
            } else {
                ref = this.firebase.firestore().collection('messages').doc(id);
            }

            newMessage = {
                ...newMessage,
                title,
                body,
                navigationPath: navigationPath !== "" ? navigationPath : null,
                platform: file !== null ? 'users' : 'app',
                sendDate: timeStamp,
                id: ref.id,
                type: file !== null ? 'csv' : 'all',
                notification: null
            };

            if (notificationTitle !== null && notificationTitle !== "" && notificationEmoji !== null && notificationEmoji !== "" && notificationPriority !== null && notificationPriority !== "") {
                newMessage.notification = {
                    title: notificationTitle,
                    emoji: notificationEmoji,
                    priority: Number(notificationPriority)
                }
            }

            if (file !== null) {
                if (id == null || id === '') { // Only upload CSV on a new inbox message
                    const storageRef = this.firebase.storage().ref();
                    const fileName = `${now}-${file.name}`;
                    const fileRef = storageRef.child(`messages/${fileName}`);

                    await fileRef.put(file);

                    newMessage = {
                        ...newMessage,
                        fileName
                    }
                }
            }

            await this.firebase.firestore().collection('messages').doc(newMessage.id).set(newMessage, { merge: true });

            if (file !== null) {
                if (id == null || id === '') {
                    // Schedule a new message job to parse CSV

                    const params: any = {
                        messageId: newMessage.id,
                        message: {
                            title,
                            body,
                            navigationPath: newMessage.navigationPath,
                            sendDate: timeStamp,
                            notification: newMessage.notification
                        },
                        fileName: newMessage.fileName
                    };

                    const options: any = {
                        debug: false,
                        inbox: true
                    };

                    const newJob = this.createJob('messageJobA', 'Inbox', moment().toDate(), '2GB', options, params);
                    return this.scheduleNewJob(newJob).then(() => {
                        return Promise.resolve({
                            data: {
                                success: true,
                                message: ["Inbox successfully scheduled"]
                            }
                        });
                    }).catch((error: any) => {
                        return {
                            data: {
                                success: false,
                                message: [error.toString()]
                            }
                        }
                    });
                } else {
                    const snap = await this.firebase.firestore().collectionGroup('messages').where('masterId', '==', newMessage.id).get();
                    const batchArray: any[] = [];
                    batchArray.push(this.firebase.firestore().batch());
                    let operationCounter = 0;
                    let batchIndex = 0;

                    snap.docs.forEach((userDocRef: any) => {
                        batchArray[batchIndex].set(userDocRef.ref, {
                            title: newMessage.title,
                            body: newMessage.body,
                            navigationPath: newMessage.navigationPath,
                            sendDate: newMessage.sendDate,
                        }, { merge: true });
                        operationCounter++;

                        if (operationCounter === 499) {
                            batchArray.push(this.firebase.firestore().batch());
                            batchIndex++;
                            operationCounter = 0;
                        }
                    });

                    const batchCommitArray: any[] = [];
                    batchArray.forEach((batch) => {
                        batchCommitArray.push(batch.commit());
                    });

                    return Promise.all(batchCommitArray).then(() => {
                        return {
                            data: {
                                success: true,
                                message: ["Message successfully updated"]
                            }
                        }
                    });
                }
            }

            return Promise.resolve({
                data: {
                    success: true,
                    message: [`Inbox successfully ${id == null || id === '' ? 'scheduled' : 'updated'}`]
                }
            });
        },
        createPush: async (data: any) => {
            const {
                title,
                body,
                navigationPath,
                timeStamp,
                file,
                companyId,
            } = data;

            if (title == null || title === "" || body == null || body === "") {
                return Promise.resolve({
                    data: {
                        success: false,
                        message: ["Title and body are required"]
                    }
                })
            }

            const now = moment().unix();
            const messageRef = this.firebase.firestore().collection('messages').doc();
            let newMessage: any = {
                createdAt: now,
                title,
                body,
                navigationPath: navigationPath !== "" ? navigationPath : null,
                platform: 'push',
                sendDate: timeStamp.unix(),
                id: messageRef.id
            };

            let params: any = {
                messageId: messageRef.id,
                message: {
                    title,
                    body,
                    navigationPath: newMessage.navigationPath
                }
            };
            let options: any = {
                debug: false,
                push: true
            };

            const memory = '1GB';
            if (file !== null) {
                const storageRef = this.firebase.storage().ref();
                const fileName = `${now}-${file.name}`;
                const fileRef = storageRef.child(`messages/${fileName}`);

                await fileRef.put(file);

                params = {
                    ...params,
                    fileName
                };

                options = {
                    ...options,
                    type: 'csv'
                };

                newMessage = {
                    ...newMessage,
                    type: 'csv',
                    fileName
                }

            } else if (companyId !== '') {
                params = {
                    ...params,
                    companyId
                };

                options = {
                    ...options,
                    type: 'companyId'
                };

                newMessage = {
                    ...newMessage,
                    type: 'companyId',
                    companyId
                };
            } else {
                options = {
                    ...options,
                    type: 'all'
                };

                newMessage = {
                    ...newMessage,
                    type: 'all'
                };
            }

            await this.firebase.firestore().collection('messages').doc(newMessage.id).set(newMessage, { merge: true });

            const newJob = this.createJob('messageJobA', 'Push', timeStamp.toDate(), memory, options, params);
            return this.scheduleNewJob(newJob).then(() => {
                return Promise.resolve({
                    data: {
                        success: true,
                        message: ["Push successfully scheduled"]
                    }
                });
            }).catch((error: any) => {
                return {
                    data: {
                        success: false,
                        message: [error.toString()]
                    }
                }
            });
        },
        createEmail: async (data: any) => {
            const {
                subject,
                body,
                timeStamp,
                file,
                companyId,
            } = data;

            if (subject == null || subject === "" || body == null || body === "") {
                return Promise.resolve({
                    data: {
                        success: false,
                        message: ["Subject and body are required"]
                    }
                })
            }

            if (!body.includes('https://pocketpoints.com/unsubscribe?token={{UNSUB}}')) {
                return Promise.resolve({
                    data: {
                        success: false,
                        message: ["Email HTML is missing the UNSUB url..."]
                    }
                })
            }

            const now = moment().unix();
            const messageRef = this.firebase.firestore().collection('messages').doc();
            let newMessage: any = {
                createdAt: now,
                subject,
                body,
                platform: 'email',
                sendDate: timeStamp.unix(),
                id: messageRef.id,
                companyId
            };

            let params: any = {
                messageId: messageRef.id,
            };

            let options: any = {
                debug: false,
                email: true,
            };

            const memory = '1GB';
            if (file !== null) {
                const storageRef = this.firebase.storage().ref();
                const fileName = `${now}-${file.name}`;
                const fileRef = storageRef.child(`messages/${fileName}`);

                await fileRef.put(file);

                params = {
                    ...params,
                    fileName
                };

                options = {
                    ...options,
                    type: 'csv'
                };

                newMessage = {
                    ...newMessage,
                    type: 'csv',
                    fileName
                }
            } else if (companyId !== '') {
                params = {
                    ...params,
                    companyId
                };

                options = {
                    ...options,
                    type: 'companyId'
                };

                newMessage = {
                    ...newMessage,
                    type: 'companyId',
                    companyId
                };
            } else {
                options = {
                    ...options,
                    type: 'all'
                };

                newMessage = {
                    ...newMessage,
                    type: 'all'
                };
            }

            await this.firebase.firestore().collection('messages').doc(newMessage.id).set(newMessage, { merge: true });

            const newJob = this.createJob('messageJobA', 'Email', timeStamp.toDate(), memory, options, params);
            return this.scheduleNewJob(newJob).then(() => {
                return Promise.resolve({
                    data: {
                        success: true,
                        message: ["Email successfully scheduled"]
                    }
                });
            }).catch((error: any) => {
                return {
                    data: {
                        success: false,
                        message: [error.toString()]
                    }
                }
            });
        },
        sendPreviewEmail: async (data: any) => {
            const {
                subject,
                body,
                previewEmail
            } = data;

            if (subject == null || subject === "" || body == null || body === "" || previewEmail === "") {
                return Promise.resolve({
                    data: {
                        success: false,
                        message: ["Subject, body, and preview email are required"]
                    }
                })
            }

            if (!body.includes('https://pocketpoints.com/unsubscribe?token={{UNSUB}}')) {
                return Promise.resolve({
                    data: {
                        success: false,
                        message: ["Email HTML is missing the UNSUB url..."]
                    }
                })
            }

            const now = moment();

            const messageRef = this.firebase.firestore().collection('messages').doc();
            const newMessage: any = {
                createdAt: now.unix(),
                subject,
                body,
                platform: 'email',
                sendDate: now.unix(),
                id: messageRef.id,
                companyId: '',
                type: 'preview'
            };

            await this.firebase.firestore().collection('messages').doc(newMessage.id).set(newMessage, { merge: true });

            const params = {
                messageId: messageRef.id,
                emails: [
                    {
                        email: previewEmail,
                        unsub: 'TOKEN_HERE'
                    }
                ]
            };

            const options = {
                debug: false,
                email: true
            };

            const newJob = this.createJob('messageJobC', 'Email', now.toDate(), '256MB', options, params);
            return this.scheduleNewJob(newJob).then(() => {
                return Promise.resolve({
                    data: {
                        success: true,
                        message: ["Email successfully scheduled"]
                    }
                });
            }).catch((error: any) => {
                return {
                    data: {
                        success: false,
                        message: [error.toString()]
                    }
                }
            });
        },
        sendPreviewPush: async (data: any) => {
            const {
                title,
                body,
                navigationPath,
                previewUID,
            } = data;

            if (title == null || title === "" || body == null || body === "" || previewUID === "") {
                return Promise.resolve({
                    data: {
                        success: false,
                        message: ["Title, body, and User ID are required"]
                    }
                })
            }

            const now = moment();
            const messageRef = this.firebase.firestore().collection('messages').doc();
            const newMessage: any = {
                createdAt: now.unix(),
                title,
                body,
                navigationPath: navigationPath !== "" ? navigationPath : null,
                platform: 'push',
                sendDate: now.unix(),
                id: messageRef.id,
                type: 'preview'
            };

            await this.firebase.firestore().collection('messages').doc(newMessage.id).set(newMessage, { merge: true });

            const params = {
                messageId: messageRef.id,
                message: {
                    title,
                    body,
                    navigationPath: newMessage.navigationPath
                },
                uids: [previewUID]
            };

            const options = {
                debug: false,
                push: true
            };

            const newJob = this.createJob('messageJobC', 'Push', now.toDate(), '512MB', options, params);
            return this.scheduleNewJob(newJob).then(() => {
                return Promise.resolve({
                    data: {
                        success: true,
                        message: ["Push successfully scheduled"]
                    }
                });
            }).catch((error: any) => {
                return {
                    data: {
                        success: false,
                        message: [error.toString()]
                    }
                }
            });
        }
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>({}, this.initialState);

    constructor(private firebase: any) { }

    private async getMessages(type: string) {
        const ref = this.firebase.firestore().collection('messages').where('platform', '==', type);
        const snap = await ref.get();

        const messages: any[] = [];
        snap.forEach((doc: any) => {
            let message = doc.data();
            if (type === 'email') {
                message.body = null;
            }

            let segment = 'All';
            if (message.type === 'csv') {
                segment = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGEBUCKET}/o/messages%2F${message.fileName}?alt=media`;
            } else if (message.type === 'companyId') {
                segment = `Company: ${message.companyId}`
            } else if (message.type === 'preview') {
                segment = 'Preview'
            }

            message = {
                ...message,
                segment
            };
            messages.push(message);
        });

        return messages.sort((lhs: any, rhs: any) => {
            return lhs.createdAt < rhs.createdAt ? 1 : -1
        });
    }

    private async deleteMessageJobs(messageId: string) {
        const snapshot = await this.firebase.firestore().collection('scheduler/queue/jobs')
            .where('args.params.messageId', '==', messageId)
            .limit(500)
            .get();

        const batch = this.firebase.firestore().batch();

        let deleted = 0;
        snapshot.docs.forEach((doc: any) => {
            batch.delete(doc.ref);
            deleted++;
        });

        return batch.commit().then(() => {
            if (deleted === 0) {
                return this.firebase.firestore().collection('messages').doc(messageId).delete()
            }
            return this.deleteMessageJobs(messageId);
        });
    }

    private async deleteUserInbox(messageId: string) {
        const snapshot = await this.firebase.firestore().collectionGroup('messages')
            .where('masterId', '==', messageId)
            .limit(500)
            .get();

        const batch = this.firebase.firestore().batch();

        let deleted = 0;
        snapshot.docs.forEach((doc: any) => {
            batch.delete(doc.ref);
            deleted++;
        });

        return batch.commit().then(() => {
            if (deleted === 0) {
                return this.firebase.firestore().collection('messages').doc(messageId).delete()
            }
            return this.deleteUserInbox(messageId);
        });
    }

    private createJob(target: string, name: string, due: Date, memory: string, options: any, params: any): IBaseJob {
        return {
            args: {
                options,
                params,
                payload: ""
            },
            cron: null,
            due,
            name,
            options: {
                output: false,
                outputHistory: false
            },
            profile: {
                memory
            },
            retry: false,
            state: 'scheduled',
            target
        };
    }

    private scheduleNewJob(newJob: IBaseJob) {
        const ref = this.firebase.firestore().collection('scheduler/queue/jobs').doc();
        return ref.set(newJob);
    }
}
