import { withStyles } from '@material-ui/core';
import React from 'react';
import RevenueForm from './RevenueForm';
import styles from './styles';

interface IProps {
    classes: any;
}

class Chargebee extends React.Component<IProps> {
    public render() {
        return (
            <React.Fragment>
                <RevenueForm />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Chargebee);
