import { Card, CardContent, Grid, TextField, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Submit } from '../../components';

interface IProps {
    classes: any;
    actions: any;
}

interface IState {
    loading: boolean;
    oldSchoolID: string;
    newSchoolID: string;
    response?: any;
    error?: any;
}

class SchoolTransfer extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            oldSchoolID: '',
            newSchoolID: '',
            response: null,
            error: null,
        };
    }

    public render() {
        const { classes } = this.props;
        const { loading, oldSchoolID, newSchoolID, response, error } = this.state;

        return (
            <Card square={true} className={classes.rootCard}>
                <CardContent>
                    <Typography variant="h6" gutterBottom={true}>
                        School Transfer
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom={true}>
                        This will transfer all users and teachers from the old school to the new school
                    </Typography>
                    <Grid container={true} direction="column">
                        <Grid item={true}>
                            <TextField
                                id="standard-textarea"
                                label="Old School ID"
                                placeholder="4"
                                margin="normal"
                                onChange={(event) => this.setState({ oldSchoolID: event.target.value })}
                                value={oldSchoolID}
                            />
                        </Grid>

                        <Grid item={true}>
                            <TextField
                                id="standard-textarea"
                                label="New School ID"
                                placeholder="6"
                                margin="normal"
                                onChange={(event) => this.setState({ newSchoolID: event.target.value })}
                                value={newSchoolID}
                            />
                        </Grid>

                        <Grid item={true}>
                            <Submit
                                loading={loading}
                                onSubmit={this.handleUpdate}
                                ButtonProps={{
                                    variant: 'contained',
                                    color: 'primary',
                                    type: 'button',
                                }}
                            >
                                Transfer
                            </Submit>
                        </Grid>
                        <Grid item={true} style={{ marginTop: 20 }}>
                            {response && this.renderLog()}
                            {error && this.renderError()}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    private renderError() {
        const { error } = this.state;

        return <div style={{ color: 'red' }}>{error}</div>;
    }

    private renderLog() {
        const { response } = this.state;

        return (
            <Grid container={true} direction="column">
                {response.map((log: any, index: any) => {
                    return (
                        <Grid item={true} key={index}>
                            {log}
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    private handleUpdate = () => {
        const { actions } = this.props;
        const { oldSchoolID, newSchoolID } = this.state;
        this.setState(
            {
                loading: true,
                response: null,
                error: null,
            },
            () => {
                actions
                    .update(oldSchoolID, newSchoolID)
                    .then((data: any) => {
                        if (data.data.success === true) {
                            this.setState({
                                loading: false,
                                response: data.data.message,
                            });
                        } else {
                            this.setState({
                                loading: false,
                                error: data.data.message,
                            });
                        }
                    })
                    .catch((error: any) => {
                        this.setState({
                            loading: false,
                            error: error.toString(),
                        });
                    });
            }
        );
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.schoolTransfer.actions,
    };
};

export default compose(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(SchoolTransfer);
