import { handleActions } from 'redux-actions';
import moment from 'moment';

export default class EventsReaction {
    public actions = {
        getUnmatchedEarned: (userIds: string[] = []) => {
            const types: string[] = ['Auto', 'Campus', 'Goal'];
            const queries: any[] = [];
            const earned = {};

            if (userIds.length === 0) {
                return Promise.resolve({});
            }

            userIds.forEach((userId) => {
                const query: any = this.firebase
                    .firestore()
                    .collection('events/all/Earned')
                    .where('user_id', '==', parseInt(userId, 10))
                    .get()
                    .then((results: any) => {
                        results.forEach((doc: any) => {
                            const data = doc.data();

                            if (!data.incentives_progressed && types.includes(data.type)) {
                                if (!earned[userId]) {
                                    earned[userId] = [];
                                }

                                earned[userId].push({
                                    id: doc.id,
                                    appUserId: data.app_user_id,
                                    start: moment(data.start.toDate()),
                                    end: moment(data.end.toDate()),
                                    type: data.type,
                                });
                            }
                        });
                    });

                queries.push(query);
            });

            return Promise.all(queries).then(() => {
                return earned;
            });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>({}, this.initialState);

    // private store: Store;

    constructor(private firebase: any) {}

    // public setStore = (store: Store) => {
    //     this.store = store;
    // };
}
