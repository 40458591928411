import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { NEW_SCHOOL_STATE, UPDATE_SCHOOL_STATE } from '../constants';

export default class UserRequestsReaction {
    public actions = {
        getNewSchools: (showCompleted: boolean) => {
            let newSchoolRef = this.firebase
                .firestore()
                .collection('requests')
                .doc('portalUser')
                .collection('newSchool');

            if (!showCompleted) {
                newSchoolRef = newSchoolRef.where('completed', '==', false);
            }

            if (this.newSchoolObserver) {
                this.newSchoolObserver();
            }

            return new Promise((resolve) => {
                this.newSchoolObserver = newSchoolRef.onSnapshot((newSchoolSnapshot: any) => {
                    const newSchools = {};

                    newSchoolSnapshot.forEach((doc: any) => {
                        newSchools[doc.id] = doc.data();
                    });

                    this.store.dispatch(this.setNewSchools(newSchools));
                    resolve();
                });
            });
        },
        updateNewSchool: (key: string, record: any) => {
            const completed = record.portalState !== NEW_SCHOOL_STATE.NOT_SAVED && record.emailedUser;

            return this.firebase
                .firestore()
                .collection('requests')
                .doc('portalUser')
                .collection('newSchool')
                .doc(key)
                .update({ ...record, completed });
        },
        getUpdateSchools: (showCompleted: boolean) => {
            let updateSchoolRef = this.firebase
                .firestore()
                .collection('requests')
                .doc('portalUser')
                .collection('updateSchool');

            if (!showCompleted) {
                updateSchoolRef = updateSchoolRef.where('completed', '==', false);
            }

            if (this.updateSchoolObserver) {
                this.updateSchoolObserver();
            }

            return new Promise((resolve) => {
                this.updateSchoolObserver = updateSchoolRef.onSnapshot((updateSchoolSnapshot: any) => {
                    const updateSchools = {};

                    updateSchoolSnapshot.forEach((doc: any) => {
                        updateSchools[doc.id] = doc.data();
                    });

                    this.store.dispatch(this.setUpdateSchools(updateSchools));
                    resolve();
                });
            });
        },
        updateUpdateSchool: (key: string, record: any) => {
            const completed = record.portalState !== UPDATE_SCHOOL_STATE.NOT_SAVED && record.emailedUser;

            return this.firebase
                .firestore()
                .collection('requests')
                .doc('portalUser')
                .collection('updateSchool')
                .doc(key)
                .update({ ...record, completed });
        },
    };

    public initialState = {
        actions: this.actions,
        data: {
            newSchools: null,
            updateSchools: null,
        },
    };

    public reducer = handleActions<any>(
        {
            SET_NEW_SCHOOLS: (state, action) => {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        newSchools: action.payload,
                    },
                };
            },
            SET_UPDATE_SCHOOLS: (state, action) => {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        updateSchools: action.payload,
                    },
                };
            },
        },
        this.initialState
    );

    private setNewSchools = createAction('SET_NEW_SCHOOLS');
    private setUpdateSchools = createAction('SET_UPDATE_SCHOOLS');
    private store: Store;
    private newSchoolObserver: any;
    private updateSchoolObserver: any;

    constructor(private firebase: any) {}

    public setStore = (store: Store) => {
        this.store = store;
    };
}
