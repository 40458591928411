import {
    Card,
    CardContent,
    CircularProgress,
    Fab,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Formik, Form } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { compose } from 'recompose';
import { Submit } from '../../components';
import RevenueModel, { IPlan, IRevenueModel, REVENUE_LENGHTS, REVENUE_TERMS, REVENUE_TYPES } from '../../models/Revenue';
import styles from './RevenueForm.styles';

interface IProps {
    classes: any;
    actions: any;
}

interface IState {
    invoices: IRevenueModel[];
    imported: boolean;
    initted: boolean;
    plans: IPlan[];
}

class RevenueForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            invoices: [],
            imported: false,
            initted: false,
            plans: [],
        };
    }

    public componentDidMount() {
        const { actions } = this.props;

        actions
            .getPlans()
            .then((plans: IPlan[]) => {
                this.setState({ initted: true, plans });
            })
            .catch(() => this.setState({ initted: true }));
    }

    public render() {
        const { classes } = this.props;
        const { imported, initted, invoices, plans } = this.state;

        return (
            <Card square={true} className={classes.rootCard}>
                {!initted && (
                    <div className={classes.rootCardOverlay}>
                        <CircularProgress />
                    </div>
                )}
                <CardContent>
                    <Typography variant="h6" gutterBottom={true}>
                        Import Revenue
                    </Typography>
                    <Formik
                        initialValues={RevenueModel.initialValues}
                        onSubmit={this.onAddInvoice}
                        validationSchema={RevenueModel.validationSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            setSubmitting,
                            resetForm,
                            setFieldValue,
                        }) => (
                            <Form noValidate={true}>
                                <MediaQuery maxWidth={960}>
                                    {(matches: boolean) => (
                                        <React.Fragment>
                                            <Grid container={true} item={true} xs={12} sm={10} lg={8} spacing={matches ? 0 : 10}>
                                                <Grid container={true} item={true} md={12} lg={6} spacing={8} alignContent="flex-start">
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <TextField
                                                            margin="normal"
                                                            fullWidth={true}
                                                            label="Customer Id"
                                                            id="customerId"
                                                            value={values.customerId}
                                                            onChange={handleChange}
                                                            required={true}
                                                            error={!!errors.customerId && !!touched.customerId}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <FormControl margin="normal" fullWidth={true}>
                                                            <InputLabel htmlFor="term">Term</InputLabel>
                                                            <Select
                                                                fullWidth={true}
                                                                value={values.term}
                                                                onChange={handleChange}
                                                                inputProps={{
                                                                    name: 'term',
                                                                    id: 'term',
                                                                }}
                                                            >
                                                                {Object.keys(REVENUE_TERMS).map((key, index) => {
                                                                    return (
                                                                        <MenuItem key={index} value={REVENUE_TERMS[key].value}>
                                                                            {REVENUE_TERMS[key].label}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={values.type === 'ad' ? 4 : 6}>
                                                        <FormControl margin="normal" fullWidth={true}>
                                                            <InputLabel htmlFor="type">Type</InputLabel>
                                                            <Select
                                                                fullWidth={true}
                                                                value={values.type}
                                                                onChange={(event) => this.onChangeType(event, setFieldValue)}
                                                                inputProps={{
                                                                    name: 'type',
                                                                    id: 'type',
                                                                }}
                                                            >
                                                                {Object.keys(REVENUE_TYPES).map((key, index) => {
                                                                    return (
                                                                        <MenuItem key={index} value={REVENUE_TYPES[key].value}>
                                                                            {REVENUE_TYPES[key].label}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    {values.type === 'ad' && (
                                                        <Grid item={true} xs={12} sm={4}>
                                                            <FormControl margin="normal" fullWidth={true}>
                                                                <InputLabel htmlFor="planType">Online</InputLabel>
                                                                <Select
                                                                    fullWidth={true}
                                                                    value={values.planType}
                                                                    onChange={handleChange}
                                                                    inputProps={{
                                                                        name: 'planType',
                                                                        id: 'planType',
                                                                    }}
                                                                >
                                                                    <MenuItem value={'online'}>Yes</MenuItem>
                                                                    <MenuItem value={'local'}>No</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    )}
                                                    {values.type === 'ad' && (
                                                        <Grid item={true} xs={12} sm={4}>
                                                            <FormControl margin="normal" fullWidth={true}>
                                                                <InputLabel htmlFor="length">Length</InputLabel>
                                                                <Select
                                                                    fullWidth={true}
                                                                    value={values.length}
                                                                    onChange={handleChange}
                                                                    inputProps={{
                                                                        name: 'length',
                                                                        id: 'length',
                                                                    }}
                                                                >
                                                                    {REVENUE_LENGHTS.map((length, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={length}>
                                                                                {`${length} Month${length > 1 ? 's' : ''}`}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    )}
                                                    {values.type === 'local' && (
                                                        <Grid item={true} xs={12} sm={6}>
                                                            <FormControl
                                                                margin="normal"
                                                                fullWidth={true}
                                                                error={!!errors.planId && touched.planId}
                                                            >
                                                                <InputLabel htmlFor="length" shrink={true}>
                                                                    Plan
                                                                </InputLabel>
                                                                <Select
                                                                    fullWidth={true}
                                                                    value={values.planId}
                                                                    onChange={handleChange}
                                                                    inputProps={{
                                                                        name: 'planId',
                                                                        id: 'planId',
                                                                    }}
                                                                >
                                                                    {plans.map((plan, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={plan.id}>
                                                                                {plan.name}
                                                                            </MenuItem>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    )}
                                                    <Grid item={true} xs={12}>
                                                        <TextField
                                                            margin="normal"
                                                            fullWidth={true}
                                                            label="Line Item"
                                                            id="lineItem"
                                                            value={values.lineItem}
                                                            onChange={handleChange}
                                                            required={true}
                                                            error={!!errors.lineItem && !!touched.lineItem}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            className={classes.marginBottom}
                                                        />
                                                    </Grid>
                                                    <Grid container={true} item={true} xs={12} spacing={8}>
                                                        <Grid item={true} xs={6}>
                                                            <DatePicker
                                                                margin="normal"
                                                                label="Date"
                                                                views={['month']}
                                                                disableFuture={true}
                                                                clearable={false}
                                                                name="date"
                                                                value={values.date}
                                                                format="MM/01/YY"
                                                                onChange={(date) => this.onChangeDate(date, setFieldValue)}
                                                                shouldDisableDate={this.handleDisableDate}
                                                                error={!!(errors.date && touched.date)}
                                                            />
                                                        </Grid>
                                                        <Grid item={true} xs={4}>
                                                            <TextField
                                                                margin="normal"
                                                                required={true}
                                                                fullWidth={true}
                                                                label="Amount"
                                                                id="amount"
                                                                value={values.amount}
                                                                onChange={handleChange}
                                                                error={!!errors.amount && !!touched.amount}
                                                                helperText={
                                                                    errors.amount && touched.amount ? 'Must be at least 0.01' : null
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item={true} xs={2} className={classes.alignFab}>
                                                            <Fab size="small" color="primary" onClick={() => handleSubmit()}>
                                                                <Icons.Add />
                                                            </Fab>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid container={true} item={true} md={12} lg={6}>
                                                    <Card raised={false} square={true} className={classes.flex}>
                                                        <CardContent>
                                                            <Typography variant="subtitle1" gutterBottom={true}>
                                                                Invoices {imported ? 'imported' : 'to import'}:
                                                            </Typography>

                                                            {invoices.length > 0 && (
                                                                <List dense={true} className={classes.flex}>
                                                                    {this.renderInvoices()}
                                                                </List>
                                                            )}
                                                            <Submit
                                                                loading={isSubmitting}
                                                                onSubmit={() => this.onSubmit(setSubmitting, resetForm)}
                                                                ButtonProps={{
                                                                    disabled: invoices.length === 0 || imported,
                                                                    variant: 'contained',
                                                                    type: 'button',
                                                                }}
                                                            >
                                                                Import
                                                            </Submit>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </MediaQuery>
                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
        );
    }

    // private renderDate = ({ field, form, ...other }: any) => {
    //     const value = form.values.date !== '' ? moment(form.values.date, 'MM/DD/YYYY') : moment().startOf('month');

    //     return (
    //         <DatePicker
    //             views={['month']}
    //             disableFuture={true}
    //             clearable={false}
    //             name={field.name}
    //             value={value}
    //             format="MM/01/YY"
    //             helperText=""
    //             onError={(_, error) => form.setFieldError(field.name, error)}
    //             onChange={(date) => this.onChangeDate(field.name, date, form)}
    //             mask={[/\d/, /\d/, '/', '0', '1', '/', /\d/, /\d/]}
    //             shouldDisableDate={this.handleDisableDate}
    //             {...other}
    //         />
    //     );
    // };

    private renderInvoices = () => {
        const { classes } = this.props;
        const { invoices } = this.state;

        return invoices.map((invoice: IRevenueModel, index: number) => {
            const secondary = `Term: ${invoice.term}, Type: ${invoice.type}, Date: ${invoice.date}`;

            return (
                <ListItem key={index}>
                    <Typography variant="subtitle2" className={classes.customerId}>
                        {invoice.customerId}
                    </Typography>
                    <ListItemText
                        primary={`${invoice.lineItem}, ${numeral(invoice.amount).format('$0,000.00')}`}
                        secondary={invoice.status.code === 'error' ? `Error: ${invoice.status.message}` : secondary}
                        secondaryTypographyProps={{ color: invoice.status.code === 'error' ? 'error' : undefined }}
                    />
                    <ListItemSecondaryAction>
                        {invoice.status.code === 'pending' && (
                            <IconButton onClick={() => this.onRemoveInvoice(index)}>
                                <Icons.Delete />
                            </IconButton>
                        )}
                        {invoice.status.code === 'error' && <Icons.ErrorOutline color="error" />}
                        {invoice.status.code === 'success' && <Icons.CheckCircleOutline color="primary" />}
                    </ListItemSecondaryAction>
                </ListItem>
            );
        });
    };

    private onChangeDate = (date: any, setFieldValue: any) => {
        const value = moment(date).format('MM/01/YYYY');

        setFieldValue('date', value, true);
    };

    private handleDisableDate = (date: any) => {
        if (date) {
            return date.format('D') !== '1';
        }

        return false;
    };

    private onRemoveInvoice = (index: number) => {
        const invoices = [...this.state.invoices];

        invoices.splice(index, 1);

        this.setState({ invoices });
    };

    private onAddInvoice = (values: IRevenueModel, { resetForm }: any) => {
        const invoices = this.state.imported ? [] : this.state.invoices;

        this.setState({ invoices: [...invoices, { ...values }], imported: false }, () => {
            resetForm({
                ...values,
                amount: 0,
                date: moment()
                    .startOf('month')
                    .format('MM/DD/YYYY'),
            });
        });
    };

    private onChangeType = (event: any, setFieldValue: any) => {
        const {
            target: { value },
        } = event;

        setFieldValue('type', value);
        setFieldValue('length', 1);

        if (value !== 'local') {
            setFieldValue('planId', '');
        }
    };

    private onSubmit = (setSubmitting: any, resetForm: any) => {
        const { actions } = this.props;
        const { invoices } = this.state;

        setSubmitting(true);

        actions.createInvoices(invoices).then((results: IRevenueModel[]) => {
            this.setState({ invoices: results, imported: true }, () => {
                resetForm();
            });
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.chargebee.actions,
    };
};

export default compose(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(RevenueForm);
