import { AxiosInstance } from 'axios';
import { handleActions } from 'redux-actions';

export default class SchoolTransferReaction {
    public actions = {
        update: (oldSchool: number, newSchool: number) => {
            return this.firebase
                .auth()
                .currentUser.getIdToken()
                .then((token: string) => {
                    return this.axios.post(
                        '/schoolTransfer/update',
                        {
                            data: {
                                oldSchool,
                                newSchool
                            },
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>({}, this.initialState);

    constructor(private firebase: any, private axios: AxiosInstance) {}
}
