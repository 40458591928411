import { createStyles, Theme } from '@material-ui/core';

const drawerWidth = 240;

const styles = (theme: Theme): Record<string, any> =>
    createStyles({
        appBar: {
            transition: theme.transitions.create(['width', 'margin'], {
                duration: theme.transitions.duration.leavingScreen,
                easing: theme.transitions.easing.sharp,
            }),
            zIndex: theme.zIndex.drawer + 1,
        },
        appBarShift: {
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['width', 'margin'], {
                duration: theme.transitions.duration.enteringScreen,
                easing: theme.transitions.easing.sharp,
            }),
            width: `calc(100% - ${drawerWidth}px)`,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
        drawer: {
            flexShrink: 0,
            whiteSpace: 'nowrap',
            width: drawerWidth,
        },
        drawerClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                duration: theme.transitions.duration.leavingScreen,
                easing: theme.transitions.easing.sharp,
            }),
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        drawerOpen: {
            transition: theme.transitions.create('width', {
                duration: theme.transitions.duration.enteringScreen,
                easing: theme.transitions.easing.sharp,
            }),
            width: drawerWidth,
        },
        grow: {
            flexGrow: 1,
        },
        hide: {
            display: 'none',
        },
        icon: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        menuButton: {
            marginLeft: 12,
            marginRight: 36,
        },
        root: {
            display: 'flex',
        },
        toolbar: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        toolbarGutter: {
            paddingRight: theme.spacing(2),
        },
        toolbarGutterOpen: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    });

export default styles;
