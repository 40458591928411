import { Card, CardContent, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';

interface IProps {
    classes: any;
}

class Dashboard extends React.Component<IProps> {
    public render() {
        return (
            <Card square={true}>
                <CardContent>
                    <Typography variant="h6" gutterBottom={true}>
                        Dashboard
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(Dashboard);
