import { AppBar, Tabs, Tab, withStyles } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import ClassroomData from './ClassroomData';
import SchoolTransfer from './SchoolTransfer';
import NewSchools from './NewSchools';
import UpdateSchools from './UpdateSchools';

interface IProps {
    classes: any;
}

interface IState {
    tab: number;
}

class Rewards extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            tab: 0,
        };
    }

    public render() {
        const { tab } = this.state;

        return (
            <React.Fragment>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tab}
                        onChange={this.onTabChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Classroom Data" />
                        <Tab label="School Transfer" />
                        <Tab label="New School Requests" />
                        <Tab label="Update School Requests" />
                    </Tabs>
                </AppBar>
                {tab === 0 && <ClassroomData />}
                {tab === 1 && <SchoolTransfer />}
                {tab === 2 && <NewSchools />}
                {tab === 3 && <UpdateSchools />}
            </React.Fragment>
        );
    }

    private onTabChange = (event: any, tab: number) => {
        this.setState({ tab });
    };
}

export default withStyles(styles)(Rewards);
